
import { Vue, Component } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
import { Filter } from "@/cms-services/filter";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
@Component
export default class Home extends Vue {
  dataSource: any = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 100,
      filter: JSON.stringify([new Filter("companyId", +this.$route.params.id)]),
    },
    className: "CompanyBranch",
  });

  companyObjectDataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "company",
  });
  loaded: boolean = false;

  createItemModel: any = {
    companyId: +this.$route.params.id,
    caption: "",
    price: "",
  };

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Компании",
        to: "/manage/compnaies/",
        exact: true,
      },
      {
        text: this.companyObjectDataSource.model?.caption,
        to: `/manage/companies/${+this.$route.params.id}`,
        exact: true,
      },
      {
        text: "Филиалы",
        to: `/manage/companies/${+this.$route.params.id}/branches`,
        exact: true,
      },
    ];
  }

  created() {
    this.companyObjectDataSource.get();
  }
}
